import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import {
  LogoFull,
  LogoIcon,
} from '@internal/backstage-plugin-gs-backstage-themes-react';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { FeatureFlagged } from '@backstage/core-app-api';
import FilterCenterFocus from '@material-ui/icons/FilterCenterFocus';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Person from '@material-ui/icons/Person';
import Group from '@material-ui/icons/Group';
import Domain from '@material-ui/icons/Domain';
import BuildIcon from '@material-ui/icons/Build';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const catalogFilter = (kind: string) =>
    encodeURI(`catalog?filters[kind]=${kind}`);

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarItem
          icon={CreateComponentIcon}
          to="catalog-import"
          text="Import..."
        />
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to="catalog" text="Catalog">
            <SidebarSubmenu title="Catalog">
              <SidebarSubmenuItem icon={HomeIcon} to="catalog" title="All" />

              <SidebarDivider />

              <SidebarSubmenuItem
                icon={Domain}
                to={catalogFilter('domain')}
                title="Domains"
              />

              <SidebarSubmenuItem
                icon={ExtensionIcon}
                to={catalogFilter('system')}
                title="Systems"
              />

              <SidebarSubmenuItem
                icon={ExtensionIcon}
                to={catalogFilter('component')}
                title="Components"
              />

              <SidebarSubmenuItem
                icon={ExtensionIcon}
                to={catalogFilter('resource')}
                title="Resources"
              />

              <SidebarDivider />

              <SidebarSubmenuItem
                icon={AlternateEmail}
                to={catalogFilter('technology')}
                title="Technologies"
              />

              <SidebarSubmenuItem
                icon={AlternateEmail}
                to={catalogFilter('radar')}
                title="Radars"
              />

              <SidebarDivider />

              <SidebarSubmenuItem
                icon={Group}
                to={catalogFilter('group')}
                title="Groups"
              />

              <SidebarSubmenuItem
                icon={Person}
                to={catalogFilter('user')}
                title="Users"
              />
              <SidebarDivider />

              <SidebarSubmenuItem
                icon={Group}
                to={catalogFilter('location')}
                title="Locations"
              />
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem icon={FilterCenterFocus} to="radars" text="Radars" />
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
          <FeatureFlagged with="developer-mode">
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
          </FeatureFlagged>
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};

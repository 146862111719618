import React from 'react';
import { ResponseErrorPanel, TableColumn } from '@backstage/core-components';
import {
  catalogApiRef,
  columnFactories,
  EntityTable,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { useAsync } from 'react-use';
import { Box } from '@material-ui/core';

function parseLocation(
  location?: string,
): { type: string; target: string } | null {
  if (location) {
    const parts = location.split(/^([^:]+):(.+)$/);
    if (parts.length >= 3) {
      return {
        type: parts[1],
        target: parts[2],
      };
    }
  }
  return null;
}

export const LocationCard = () => {
  const catalogApi = useApi(catalogApiRef);
  const { entity } = useEntity();
  const { loading, error, value } = useAsync(async () => {
    const loc1 =
      entity.metadata?.annotations?.['backstage.io/managed-by-location'];
    const loc2 =
      entity.metadata?.annotations?.['backstage.io/managed-by-origin-location'];
    const locs = [...new Set([loc1, loc2].filter(l => l))];
    if (locs.length > 0) {
      const filters: Record<string, string | string[]>[] = locs.reduce(
        (list, loc) => {
          const parsed = parseLocation(loc);
          if (parsed) {
            list.push({
              kind: 'Location',
              'spec.type': parsed.type,
              'spec.targets': [parsed.target],
            });
            list.push({
              kind: 'Location',
              'spec.type': parsed.type,
              'spec.target': parsed.target,
            });
          }
          return list;
        },
        [] as Record<string, string | string[]>[],
      );

      const result = await catalogApi.getEntities({
        filter: filters,
      });

      return {
        locations: result.items,
      };
    }
    return null;
  }, [catalogApi, entity]);

  if (error) {
    return (
      <Box mb={1}>
        <ResponseErrorPanel error={error} />
      </Box>
    );
  }

  if (loading || !value) {
    return null;
  }

  const columns: TableColumn<Entity>[] = [
    columnFactories.createEntityRefColumn({ defaultKind: 'Location' }),
  ];

  const noLocations = <b>No locations</b>;

  return (
    <EntityTable
      title="Locations"
      emptyContent={noLocations}
      entities={value.locations}
      columns={columns}
    />
  );
};

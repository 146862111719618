/**
 * This file is a modified variant of
 * https://github.com/backstage/backstage/blob/v1.20.0-next.0/plugins/catalog/src/components/DependencyOfComponentsCard/DependencyOfComponentsCard.tsx for the Genius Sports Backstage service
 */
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  RELATION_DEPENDENCY_OF,
  ComponentEntity,
  Entity,
} from '@backstage/catalog-model';
import { InfoCardVariants, TableColumn } from '@backstage/core-components';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';
import { EntityTable } from '@backstage/plugin-catalog-react';

/** @public */
export interface DependencyOfComponentsCardProps {
  variant?: InfoCardVariants;
  title?: string;
}

export const componentEntityColumns: TableColumn<ComponentEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'component' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

export const componentEntityHelpLink: string =
  'https://backstage.io/docs/features/software-catalog/descriptor-format#kind-component';
export const asComponentEntities = (entities: Entity[]): ComponentEntity[] =>
  entities as ComponentEntity[];

export function DependencyOfEntitiesCard(
  props: DependencyOfComponentsCardProps,
) {
  const { variant = 'gridItem', title = 'Entities with Dependency' } = props;
  return (
    <RelatedEntitiesCard
      variant={variant}
      title={title}
      relationType={RELATION_DEPENDENCY_OF}
      columns={componentEntityColumns}
      emptyMessage="No component depends on this component"
      emptyHelpLink={componentEntityHelpLink}
      asRenderableEntities={asComponentEntities}
    />
  );
}

import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  UnifiedThemeProvider,
} from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { GeniusDefaultPageTheme } from './genius-palette';

export const GeniusDarkUnifiedTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.dark,
    fontFamily: 'system-ui, "Red Hat Text", "Ubuntu", "Droid Sans", sans-serif',
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: GeniusDefaultPageTheme,
    documentation: GeniusDefaultPageTheme,
    tool: GeniusDefaultPageTheme,
    service: GeniusDefaultPageTheme,
    website: GeniusDefaultPageTheme,
    library: GeniusDefaultPageTheme,
    other: GeniusDefaultPageTheme,
    app: GeniusDefaultPageTheme,
    apis: GeniusDefaultPageTheme,
  },
});

export const GeniusDarkAppTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = {
  id: 'genius-dark',
  title: 'Genius Dark',
  variant: 'dark',
  Provider: ({ children }) => {
    return (
      <UnifiedThemeProvider theme={GeniusDarkUnifiedTheme}>
        <CssBaseline>{children}</CssBaseline>
      </UnifiedThemeProvider>
    );
  },
};

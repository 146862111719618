import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  UnifiedThemeProvider,
} from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { GeniusDefaultPageTheme } from './genius-palette';

export const GeniusLightUnifiedTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.light,
    fontFamily: 'system-ui, "Red Hat Text", "Ubuntu", "Droid Sans", sans-serif',
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: GeniusDefaultPageTheme,
    documentation: GeniusDefaultPageTheme,
    tool: GeniusDefaultPageTheme,
    service: GeniusDefaultPageTheme,
    website: GeniusDefaultPageTheme,
    library: GeniusDefaultPageTheme,
    other: GeniusDefaultPageTheme,
    app: GeniusDefaultPageTheme,
    apis: GeniusDefaultPageTheme,
  },
});

export const GeniusLightAppTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> =
  {
    id: 'genius-light',
    title: 'Genius Light',
    variant: 'light',
    Provider: ({ children }) => {
      return (
        <UnifiedThemeProvider theme={GeniusLightUnifiedTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      );
    },
  };

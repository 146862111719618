import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { themes, UnifiedThemeProvider } from '@backstage/theme';

export const DefaultLightAppTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> =
  {
    id: 'default-light',
    title: 'Backstage Light',
    variant: 'light',
    Provider: ({ children }) => {
      return (
        <UnifiedThemeProvider theme={themes.light}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      );
    },
  };

import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { themes, UnifiedThemeProvider } from '@backstage/theme';

export const DefaultDarkAppTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> =
  {
    id: 'default-dark',
    title: 'Backstage Dark',
    variant: 'dark',
    Provider: ({ children }) => {
      return (
        <UnifiedThemeProvider theme={themes.dark}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      );
    },
  };

import {
  createExternalRouteRef,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'gs-radars:index-page',
});

export const radarRouteRef = createRouteRef({
  id: 'gs-radars:standalone-radar-page',
  params: ['namespace', 'kind', 'name'],
});

export const entityRadarRouteRef = createExternalRouteRef({
  id: 'gs-radars:external-radar-page',
  params: ['namespace', 'kind', 'name'],
});

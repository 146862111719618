import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { entityRadarRouteRef, radarRouteRef, rootRouteRef } from './routes';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { RadarApiClient } from './RadarApiClient';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const gsRadarsPlugin = createPlugin({
  id: 'gs-tech-radars',
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    entityRadar: entityRadarRouteRef,
  },
  apis: [
    createApiFactory({
      api: techRadarApiRef,
      deps: {
        catalog: catalogApiRef,
      },
      factory: deps => {
        return new RadarApiClient(deps.catalog);
      },
    }),
  ],
});

export const GSRadarsIndexPage = gsRadarsPlugin.provide(
  createRoutableExtension({
    name: 'GSRadarsIndexPage',
    component: () =>
      import('./components/RadarsIndexPage').then(m => m.RadarsIndexPage),
    mountPoint: rootRouteRef,
  }),
);

export const GSCustomRadarPage = gsRadarsPlugin.provide(
  createRoutableExtension({
    name: 'GSCustomRadarPage',
    component: () =>
      import('./components/CustomRadarPage').then(m => m.CustomRadarPage),
    mountPoint: radarRouteRef,
  }),
);

export const GSEmbeddedRadar = gsRadarsPlugin.provide(
  createComponentExtension({
    name: 'GSEmbeddedRadar',
    component: {
      lazy: () =>
        import('./components/CustomRadarPage').then(m => m.EmbeddedEntityRadar),
    },
  }),
);

import { OAuth2 } from '@backstage/core-app-api';
import {
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { auth0AuthApiRef } from './auth0-auth-api-ref';

export default createApiFactory({
  api: auth0AuthApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      configApi,
      discoveryApi,
      oauthRequestApi,
      provider: {
        id: 'auth0',
        title: 'Auth0',
        icon: () => null,
      },
      environment: configApi.getOptionalString('auth.environment'),
      defaultScopes: ['openid', 'email', 'profile', 'offline_access'],
      popupOptions: {
        // optional, used to customize login in popup size
        // size: {
        //     fullscreen: true,
        // },
        /**
                 * or specify popup width and height
                 * size: {
                 width: 1000,
                 height: 1000,
                 }
                 */
      },
    }),
});

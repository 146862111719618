import { genPageTheme, palettes, shapes } from '@backstage/theme';
import {
  ColorPartial,
  PaletteOptions,
} from '@material-ui/core/styles/createPalette';

const White = '#ffffff';
const g50 = '#F2F2F5';
const g100 = '#E5E5EA';
const g200 = '#C7C7CC';
const g300 = '#AEAEB2';
const g400 = '#8E8E93';
const g500 = '#636366';
const g600 = '#48484A';
const g700 = '#3A3A3C';
const g800 = '#2C2C2E';
const g900 = '#1C1C1E';
const GSB50 = '#E6ECFF';
const GSB100 = '#A3BCFF';
const GSB200 = '#7A9DFF';
const GSB300 = '#4E7BF5';
const GSB400 = '#2532E8';
const GSB500 = '#0000DC';
const GSB600 = '#0000B5';
const GSB700 = '#00008F';
const GSB800 = '#000069';
const GSB900 = '#000042';

const GSG50 = '#E8FCF2';
const GSG100 = '#BBF7D9';
const GSG200 = '#8DF1C0';
const GSG300 = '#60ECA6';
const GSG400 = '#32E78D';
const GSG500 = '#18C971';
const GSG600 = '#139F5A';
const GSG700 = '#047C40';
const GSG800 = '#084426';
const GSG900 = '#03170D';

export const GSB: ColorPartial = {
  50: GSB50,
  100: GSB100,
  200: GSB200,
  300: GSB300,
  400: GSB400,
  500: GSB500,
  600: GSB600,
  700: GSB700,
  800: GSB800,
  900: GSB900,
};

export const GSG: ColorPartial = {
  50: GSG50,
  100: GSG100,
  200: GSG200,
  300: GSG300,
  400: GSG400,
  500: GSG500,
  600: GSG600,
  700: GSG700,
  800: GSG800,
  900: GSG900,
};

export const GeniusDefaultPageTheme = genPageTheme({
  colors: [GSB700, GSB400],
  shape: shapes.wave,
});

export const GeniusLight: PaletteOptions = {
  ...palettes.light,
  primary: {
    main: GSB400,
    dark: GSB600,
  },
  secondary: {
    main: GSB300,
  },
  action: {
    disabledBackground: g50,
    disabled: g200,
  },
  grey: {
    50: g50,
    100: g100,
    200: g200,
    300: g300,
    400: g400,
    500: g500,
    600: g600,
    700: g700,
    800: g800,
    900: g900,
  },
  background: {
    paper: White,
    default: White,
  },
};

export const GeniusDark = {
  ...palettes.dark,
};

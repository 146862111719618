import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import dayjs from 'dayjs';
import { Entity } from '@backstage/catalog-model';

export class RadarApiClient implements TechRadarApi {
  private readonly catalog: CatalogApi;

  constructor(catalog: CatalogApi) {
    this.catalog = catalog;
  }

  isSupportedEntity(entity: Entity) {
    if (entity.kind === 'Radar') {
      return true;
    }
    return false;
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    if (!id) {
      throw new Error(`Invalid id ${id}.`);
    }

    const entity = await this.catalog.getEntityByRef(id);

    if (!entity) {
      throw new Error(`No entity for ${id}.`);
    }

    if (!this.isSupportedEntity(entity)) {
      throw new Error(`Entity (${id}) is not supported radar kind.`);
    }

    if (!entity.spec?.data) {
      throw new Error(`Radar entity (${id}) spec.data is not defined.`);
    }

    const rawData = entity.spec.data as unknown as TechRadarLoaderResponse;
    return {
      ...rawData,
      entries: rawData.entries.map(entry => {
        return {
          ...entry,
          timeline: entry.timeline.map(item => {
            return { ...item, date: dayjs(item.date).toDate() };
          }),
        };
      }),
    } as TechRadarLoaderResponse;
  }
}

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 70,
  },
  path: {
    fill: '#fff',
  },
});

export default () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg">
      <path
        className={classes.path}
        d="M36.34008,56.34011A19.99043,19.99043,0,0,0,56.13487,33.49247H37.2407l-5.72132,5.72132H42.04149a6.37755,6.37755,0,0,1-11.08322.56113l-4.3819,4.38188a12.50532,12.50532,0,0,0,21.9423-4.943h1.83571a14.30589,14.30589,0,1,1-4.92061-13.91437l4.03764-4.03765A19.99781,19.99781,0,1,0,36.34008,56.34011ZM36.34,29.94811a6.34713,6.34713,0,0,1,3.43492,1.0102l4.3819-4.38191A12.50605,12.50605,0,0,0,24.16143,39.21379h6.477A6.37924,6.37924,0,0,1,36.34,29.94811Z"
      />
    </svg>
  );
};

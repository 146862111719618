import {
  EntityLayout,
  EntityLinksCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import React from 'react';
import { Grid } from '@material-ui/core';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { DependencyOfEntitiesCard } from '../components/DependencyOfEntityCard/DependencyOfEntitiesCard';
import { TechnologyAboutCard } from '../components/TechnologyAboutCard';

export const createTechnologyPage = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <EntitySwitch>
            <EntitySwitch.Case if={isOrphan}>
              <Grid item xs={12}>
                <EntityOrphanWarning />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <EntitySwitch>
            <EntitySwitch.Case if={hasCatalogProcessingErrors}>
              <Grid item xs={12}>
                <EntityProcessingErrorsPanel />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <Grid item md={6}>
            <TechnologyAboutCard variant="gridItem" />
          </Grid>

          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>

          <Grid item xs={12}>
            <EntityLinksCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/usage" title="Usages">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={12}>
            <DependencyOfEntitiesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};

import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { auth0AuthApiRef } from '@internal/backstage-plugin-gs-auth-node';
import {
  createSubRouteRef,
  gitlabAuthApiRef,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';

import {
  GeniusDarkAppTheme,
  GeniusLightAppTheme,
  DefaultLightAppTheme,
  DefaultDarkAppTheme,
} from '@internal/backstage-plugin-gs-backstage-themes-react';

import {
  gsRadarsPlugin,
  GSCustomRadarPage,
  GSRadarsIndexPage,
} from '@internal/backstage-plugin-gs-customizations-react';

import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';

const entityRadarRouteRef = createSubRouteRef({
  id: 'entity-radar-page',
  parent: catalogPlugin.routes.catalogEntity,
  path: '/radar',
});

const app = createApp({
  apis,
  themes: [
    GeniusDarkAppTheme,
    GeniusLightAppTheme,
    DefaultLightAppTheme,
    DefaultDarkAppTheme,
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      // createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(gsRadarsPlugin.externalRoutes, {
      entityRadar: entityRadarRouteRef,
    });
  },
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      const isDevelopment =
        configApi.getString('auth.environment') === 'development';
      if (isDevelopment) {
        return (
          <SignInPage
            {...props}
            auto
            providers={[
              'guest',
              {
                id: 'gitlab-auth-provider',
                title: 'GitLab',
                message: 'Sign in using GitLab',
                apiRef: gitlabAuthApiRef,
              },
              {
                id: 'auth0-auth-provider',
                title: 'Auth0',
                message: 'Sign in using Auth0',
                apiRef: auth0AuthApiRef,
              },
            ]}
          />
        );
      }
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'auth0-auth-provider',
            title: 'Auth0',
            message: 'Sign in using Auth0',
            apiRef: auth0AuthApiRef,
          }}
        />
      );
    },
  },
  featureFlags: [
    {
      pluginId: '', // pluginId is required for feature flags in plugins. It can be left blank for a feature flag leveraged in the application.
      name: 'developer-mode',
      description: 'Developer Mode adds additional functionality to the UI',
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route
      path="/catalog"
      element={<CatalogIndexPage pagination initialKind="system" />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />

    <Route path="/radars" element={<GSRadarsIndexPage />} />
    <Route
      path="/radar/:namespace/:kind/:name/*"
      element={<GSCustomRadarPage />}
    />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
